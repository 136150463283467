type TFetchError = { response?: Response & { _data: any } }

type TErrorHandlers = {
  handleUnknownError: (err: unknown) => void
  handle400Error?: (err: TFetchError) => void
  handle401Error?: (err: TFetchError) => void
  handle403Error?: (err: TFetchError) => void
  handle404Error?: (err: TFetchError) => void
  handle429Error?: (err: TFetchError) => void
  handle500OrAboveError?: (err: TFetchError) => void
}

export function handleFetchError(error: unknown, handlers: TErrorHandlers) {
  // Handle non FetchErrors
  if (
    typeof error === 'string' ||
    !error ||
    !Object.prototype.hasOwnProperty.call(error, 'response')
  ) {
    handlers.handleUnknownError(error)
    return
  }

  // Cast error to FetchError
  const castedError = error as TFetchError
  const responseStatus = castedError.response?.status || 500
  if (responseStatus >= 500 && handlers.handle500OrAboveError) {
    handlers.handle500OrAboveError(castedError)
  } else if (responseStatus === 404 && handlers.handle404Error) {
    handlers.handle404Error(castedError)
  } else if (responseStatus === 403 && handlers.handle403Error) {
    handlers.handle403Error(castedError)
  } else if (responseStatus === 401 && handlers.handle401Error) {
    handlers.handle401Error(castedError)
  } else if (responseStatus === 400 && handlers.handle400Error) {
    console.log('400 error', castedError)
    handlers.handle400Error(castedError)
  } else if (responseStatus === 429 && handlers.handle429Error) {
    handlers.handle429Error(castedError)
  }
}
